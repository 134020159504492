interface Props {
    heightInPx: number;
}


function Logo(props: Props) {
    return (
        <svg height={`${props.heightInPx}px`} /** height="54.529mm"*/ version="1.1" viewBox="0 0 81.446 54.529" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg">
            <g transform="translate(-64.316 -114.37)">
                <path d="m89.529 114.39 3.8192-0.0201-0.0013 50.674 21.333 0.0384 0.0118-50.649 31.07 27.348-0.027 27.118h-4.0095l0.0695-25.492-9.4494-8.3213 0.0224 33.764h-3.8793l0.0251-37.065-9.9626-8.7121-0.0359 45.807-54.199-0.0893-2.65e-4 -17.963 19.104-16.698-0.02888 5.0243-15.266 13.383 0.05096 12.486 21.348 0.0288z" strokeWidth=".26458" />
            </g>
        </svg>
    );
}

export default Logo;
