import i18n from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from "react-i18next";

const resources = {
  fr: {
    translation: {
      global: {
        chargement: "Chargement...",
        chargement_erreur: "Si le contenu n'apparait pas en moins de 5 à 10 secondes, rechargez la page.",
        voir_plus: "Voir plus",
        erreur: {
          erreur_bd: {
            titre: "Erreur de base de données..."
          }
        }
      },
      pages: {
        accueil: {
          titre: "Accueil",
          qui_sommes_nous: "Qui sommes-nous?",

          sous_titre_qui_sommes_nous: "Un entrepreneur général à Montréal – Rénovations sur mesure et de qualité",
          contenu_qui_sommes_nous_paragraphe_0: "Depuis plus de <strong>{{depuis}} ans</strong>, <strong>Construction Delamain</strong> est reconnu comme un <strong>entrepreneur général de confiance à Montréal</strong>. Spécialisés dans les <strong>rénovations résidentielles et commerciales</strong>, nous avons bâti une solide réputation grâce à notre <strong>expertise en design</strong>, notre <strong>savoir-faire exceptionnel</strong>, et une <strong>approche personnalisée adaptée à vos besoins</strong>.",
          contenu_qui_sommes_nous_paragraphe_1: "En choisissant <strong>Construction Delamain</strong>, vous optez pour une équipe expérimentée, capable de transformer vos idées en réalisations concrètes tout en respectant vos <strong>délais</strong> et <strong>budgets</strong>.",
          sous_titre_pourquoi_nous: "Pourquoi choisir Construction Delamain pour vos rénovations à Montréal ?",

          contenu_pourquoi_nous_paragraphe_0: "Nous nous engageons à :",
          liste_engagements: ["Offrir des <strong>résultats exceptionnels</strong> qui surpassent vos attentes.",
            "Respecter vos <strong>délais</strong> et <strong>budgets</strong>, peu importe la complexité du projet.",
            "Proposer des <strong>solutions innovantes</strong> face aux imprévus, aux changements d’envergure."],

          contenu_pourquoi_nous_paragraphe_1: "En plus de nos engagements, nous offrons des services complets pour simplifier vos projets :",
          liste_engagementsplus: ["<strong>Obtention des permis</strong> auprès de la ville, pour que votre projet respecte toutes les normes.",
            "<strong>Plans et conception architecturale</strong>, adaptés à vos besoins et à votre vision."],

          contenu_pourquoi_nous_paragraphe_2: "Faites confiance à notre équipe d’entrepreneurs spécialisés pour réaliser vos <strong>projets de rénovation clé en main</strong> dans toute la <strong>grande région de Montréal</strong>, incluant <strong>Laval</strong>, la <strong>Rive-Sud</strong>, la <strong>Rive-Nord</strong>, <strong>Lanaudière</strong> et les <strong>Laurentides</strong>. </strong>",

          specialites: "Services de rénovation à Montréal",
          specialites_sous_titre_reno_residentielle: "Rénovation résidentielle",
          specialites_paragraphe_reno_residentielle_0: "Nous excellons dans les <strong>projets résidentiels de grande envergure</strong> nécessitant une attention particulière et un haut niveau de finition. Découvrez notre <strong>portfolio résidentiel</strong>, où nos réalisations <strong>intérieures</strong> et <strong>extérieures</strong> témoignent de notre capacité à bâtir l’extraordinaire.",

          specialites_paragraphe_reno_residentielle_1: "Nous sommes spécialisés dans :",
          liste_specialitesresidentielles: [
            "<strong>Agrandissement de maison</strong>",
            "<strong>Projets résidentiels complexes nécessitant une expertise avancée</strong>",
            "<strong>Démolition sécurisée et modification de structures portantes.</strong>"
          ],

          specialites_sous_titre_reno_commerciale: "Rénovation commerciale ",
          specialites_paragraphe_reno_commerciale_0: "Notre expertise en <strong>rénovation commerciale</strong> couvre une large gamme de projets, incluant la <strong>conception/construction</strong> et les <strong>travaux généraux</strong>. Nous travaillons avec des entreprises de toutes tailles pour créer des espaces <strong>professionnels esthétiques et fonctionnels</strong>.",

          contacteznous: "Contactez-nous pour une soumission",
          contacteznous_details: "Prêts à transformer vos idées en réalité ? <strong>Construction Delamain</strong> est votre partenaire idéal pour des <strong>rénovations de qualité supérieure</strong>. <1>Contactez-nous dès aujourd’hui</1> pour discuter de votre projet et obtenir une <strong>soumission</strong>.",
        },
        services: {
          services: "Services",
          services_sous_titre: "Gestion de projet clé en main pour vos rénovations",
          services_paragraphe_0: "<strong>Construction Delamain</strong> est spécialisé dans la gestion complète de projets de rénovation, offrant une approche <strong>clé en main</strong> pour répondre à tous vos besoins. Que ce soit pour la transformation d’un immeuble patrimonial à Montréal, l’agrandissement d’une maison en périphérie, des rénovations complexes d’espaces commerciaux, ou encore des projets d’<strong>après-sinistre</strong>, nous sommes votre partenaire de confiance pour des résultats exceptionnels.",
          services_paragraphe_1: "Tous nos travaux sont assurés, garantis et couverts par la Régie du bâtiment du Québec ({{ license_rbq }}).",

          services_sous_titre_pourquoi_choisir: "Pourquoi choisir nos services de gestion de projet clé en main ?",
          services_paragraphe_pourquoi_choisir_0: "Avec <strong>Construction Delamain</strong>, profitez d’un accompagnement à chaque étape du processus :",
          liste_etapesprocessus: [
            "<strong>Planification</strong> : Évaluation de vos besoins, création de plans sur mesure et préparation de soumissions précises.",
            "<strong>Obtention des permis</strong> : Gestion complète des démarches administratives auprès des municipalités pour garantir la conformité de votre projet.",
            "<strong>Coordination des équipes</strong> : Supervision des travaux et collaboration avec des experts qualifiés (architectes, ingénieurs, ouvriers spécialisés).",
            "<strong>Livraison dans les délais et budgets</strong> : Un suivi rigoureux pour assurer que chaque aspect du projet respecte vos attentes."
          ],

          domaines_expertises: "Nos domaines d’expertise en rénovation",

          services_sous_titre_domaines_expertises: "1. Rénovation résidentielle",
          services_paragraphe_domaines_expertises_0: "Nous transformons des maisons, condos, et chalets en espaces modernes et fonctionnels.",
          liste_domaines_expertises: [
            "<strong>Agrandissement et réaménagement d’espaces</strong> : Création d’espaces lumineux et adaptés à votre mode de vie.",
            "<strong>Rénovations haut de gamme</strong> : Cuisines et salles de bain sur mesure, avec une attention aux moindres détails.",
            "<strong>Restauration patrimoniale</strong> : Préservation et modernisation d’immeubles anciens."
          ],

          services_sous_titre_reno_commerciale: "2. Rénovation commerciale",
          services_paragraphe_reno_commerciale_0: "Notre expertise en <strong>rénovation commerciale</strong> nous permet de collaborer avec des entreprises prestigieuses pour moderniser et optimiser leurs espaces professionnels. Nous offrons des solutions adaptées à vos besoins spécifiques :",
          liste_reno_commerciale: [
            "<strong>Transformation de bureaux et locaux commerciaux</strong> : Créez des espaces modernes et fonctionnels pour vos employés et clients.",
            "<strong>Rénovation de restaurants et espaces de divertissement</strong> : Donnez vie à vos concepts grâce à des designs sur mesure.",
            "<strong>Optimisation d’immeubles locatifs et espaces multifonctionnels</strong> : Améliorez la valeur de vos propriétés grâce à des rénovations stratégiques."
          ],

          services_sous_titre_apres_sinistre: "3. Expertise en après-sinistre",
          services_paragraphe_apres_sinistre_0: "Nous comprenons l’urgence et la complexité des projets d’<strong>après-sinistre</strong> et nous avons l’expérience nécessaire pour vous accompagner dans ces moments difficiles.",
          liste_apres_sinistre: [
            "<strong>Rénovations après dégâts d’eau</strong> : Réparation des structures, déshumidification, et rénovation complète.",
            "<strong>Reconstruction après incendie</strong> : Évaluation des dommages, démolition sécurisée, et reconstruction selon les normes les plus strictes.",
            "<strong>Intervention rapide et efficace</strong> : Coordination de tous les aspects, de la gestion des assurances à la livraison du projet fini."
          ],


          clients_prestigieux: "Clients prestigieux",
          services_paragraphe_clients_prestigieux_0: "Nous avons eu le privilège de travailler avec des clients réputés, tels que :",
          liste_clients_prestigieux: [
            "<strong>Groupe MAC</strong>",
            "<strong>CGI</strong>",
            "<strong>IRCM</strong>",
            "<strong>Imperial Tobacco</strong>",
            "<strong>Confiserie Mondoux</strong>",
            "<strong>Restaurant Biiru</strong>",
            "<strong>Restaurant NeoTokyo</strong>",
            "<strong>Restaurant Escondite</strong>",
            "<strong>Les Auberges du Cœur</strong>",
            "<strong>Théâtre du Vieux-Terrebonne</strong>",
            "<strong>L’Union Française</strong>",
            "<strong>Centre Scamma</strong>"
          ],


          services_specialises: "Nos services spécialisés",

          services_sous_titre_immobilier: "Gestion de projets immobiliers complexes",
          services_paragraphe_immobilier_0: "Nous sommes experts dans la rénovation et l’agrandissement de bâtiments nécessitant une coordination minutieuse.",
          liste_immobilier: [
            "<strong>Transformation majeure de bâtiments</strong> : Démolition sécurisée, restructuration, et modernisation complète.",
            "<strong>Rénovations pour immeubles locatifs</strong> : Reconfiguration des espaces pour améliorer leur fonctionnalité et leur attractivité."
          ],

          services_sous_titre_agrandissement: "Agrandissements en périphérie",
          services_paragraphe_agrandissement_0: "Vous souhaitez ajouter de l’espace à votre propriété ? Que ce soit pour une maison unifamiliale ou un bâtiment en périphérie, nous proposons des solutions sur mesure :",
          liste_agrandissement: [
            "Extension de pièces principales.",
            "Ajout d’étages ou d’unités supplémentaires.",
            "Intégration d’aménagements extérieurs harmonieux."
          ],

          services_sous_titre_gestion_administrative: "Gestion administrative et technique",
          liste_gestion_administrative: [
            "<strong>Obtention des permis municipaux</strong> : Une prise en charge complète pour respecter les normes de construction.",
            "<strong>Conception architecturale</strong> : Création de plans adaptés à vos besoins et aux règlements locaux.",
            "<strong>Solutions techniques avancées</strong> : Modification de structures portantes, installation de drains français, et travaux d’étanchéité."
          ],





          services_sur_mesure: "Services sur mesure pour vos projets",
          services_paragraphe_services_sur_mesure_0: "Chez <strong>Construction Delamain</strong>, nous sommes fiers de desservir la grande région de Montréal, en nous spécialisant dans les projets situés à <strong>Ville Mont-Royal (VMR)</strong>, <strong>Westmount</strong>, et <strong>Le Plateau Mont-Royal</strong>. Que ce soit pour restaurer une maison historique à <strong>Westmount</strong>, moderniser un condo au <strong>Plateau Mont-Royal</strong>, ou agrandir une propriété à <strong>VMR</strong>, notre équipe offre des rénovations de haute qualité conçues pour rehausser la beauté, la fonctionnalité et la valeur de votre espace.",
          services_paragraphe_services_sur_mesure_1: "Notre expertise s’étend également à <strong>Laval</strong>, la <strong>Rive-Sud</strong>, la <strong>Rive-Nord</strong>, <strong>Lanaudière</strong> et les <strong>Laurentides</strong>, assurant une couverture complète dans toute la région.",



          contacteznous: "Contactez-nous dès aujourd’hui",
          contacteznous_details: "Confiez vos projets à <strong>Construction Delamain</strong> pour une expérience sans tracas et des résultats à la hauteur de vos attentes. <1>Contactez-nous dès maintenant</1> pour une <strong>soumission gratuite</strong> et découvrez comment nous pouvons donner vie à vos idées.",
        },
        apropos: {
          a_propos: "À Propos",
          a_propos_sous_titre: "Construction Delamain – Votre partenaire de confiance en rénovation et construction à Montréal",
          a_propos_paragraphe_0: "Depuis {{ annee_fondation }}, <strong>Construction Delamain</strong> s’est imposée comme une entreprise primée de <strong>travaux généraux</strong>, répondant aux besoins des secteurs <strong>résidentiel</strong> et <strong>commercial</strong> dans la grande région de <strong>Montréal</strong>. Avec 90 % de nos projets provenant de recommandations et de clients fidèles, nous avons bâti une réputation solide fondée sur la qualité, la confiance et la satisfaction de nos clients.",
          a_propos_paragraphe_1: "Que ce soit pour des <strong>rénovations résidentielles de grande envergure</strong>, la transformation de bâtiments commerciaux, ou des projets complexes, nous sommes dédiés à livrer des résultats exceptionnels tout en forgeant des relations durables avec nos clients.",


          a_propos_sous_titre_respectee_reconnue: "Une entreprise de construction respectée et reconnue",
          a_propos_paragraphe_respectee_reconnue_0: "Chez <strong>Construction Delamain</strong>, nous ne nous contentons pas de bâtir des espaces d’exception, nous bâtissons également la confiance. Notre approche repose sur :",
          a_propos_liste_respectee_reconnue: [
            "<strong>L’écoute attentive</strong> de vos besoins.</li>",
            "Une <strong>collaboration étroite</strong> tout au long de votre projet.",
            "Des <strong>solutions personnalisées</strong> adaptées à vos attentes et à votre budget."
          ],
          a_propos_paragraphe_respectee_reconnue_1: "Notre engagement envers <strong>l’intégrité</strong>, le <strong>professionnalisme</strong> et la <strong>transparence</strong> est au cœur de tout ce que nous entreprenons.",

          a_propos_sous_titre_notre_equipe: "Notre équipe d’experts passionnés",
          a_propos_paragraphe_notre_equipe_0: "Depuis plus de {{ depuis }} ans, <strong>Antonie et son équipe</strong> unissent leurs talents en conception et en construction pour offrir une approche unique aux <strong>travaux généraux</strong>.",



          a_propos_sous_titre_antonie: "Antonie Bordeleau – La vision et l’énergie",
          a_propos_paragraphe_antonie_0: "Grâce à son optimisme et à son ouverture d’esprit, Antonie transforme les obstacles en opportunités. Ses qualités incluent :",
          a_propos_liste_antonie: [
            "Une <strong>écoute attentive</strong> qui garantit que vos besoins sont toujours au centre du projet.",
            "Des <strong>solutions créatives</strong> adaptées aux défis les plus complexes.",
            "Une <strong>énergie</strong> inépuisable et une approche calme qui inspire confiance."
          ],



          a_propos_sous_titre_polyvalente: "Une équipe polyvalente et dévouée",
          a_propos_paragraphe_polyvalente_0: "L’équipe de Construction Delamain est composée d’experts qualifiés et passionnés. Ensemble, ils :",
          a_propos_liste_polyvalente: [
            "L’équipe de Construction Delamain est composée d’experts qualifiés et passionnés. Ensemble, ils :",
            "Maîtrisent le multitâche pour garantir une <strong>exécution fluide</strong> et sans compromis.",
            "Travaillent avec <strong>intégrité</strong> et <strong>transparence</strong> pour assurer votre satisfaction."
          ],



          a_propos_sous_titre_philosophie: "La philosophie Delamain",
          a_propos_paragraphe_philosophie_0: "Les valeurs partagées par Antonie et son équipe forment le socle de <strong>Construction Delamain</strong> :",
          a_propos_liste_philosophie: [
            "<strong>Intégrité</strong> dans nos pratiques.",
            "<strong>Professionnalisme</strong> dans chaque détail.",
            "<strong>Transparence</strong> avec nos clients, nos partenaires et nos équipes."
          ],



          a_propos_sous_titre_pourquoi: "Pourquoi choisir Construction Delamain ?",
          a_propos_liste_pourquoi: [
            "<strong>Une expertise éprouvée</strong> : Plus de {{ depuis }} ans d’expérience dans les secteurs résidentiel et commercial.",
            "<strong>Une réputation bâtie sur la confiance</strong> : 90 % de nos projets proviennent de recommandations ou de clients fidèles.",
            "<strong>Des projets sur mesure</strong> : Chaque rénovation ou construction est adaptée à vos besoins uniques.",
            "<strong>Une couverture régionale</strong> : Nous desservons toute la grande région de Montréal, incluant <strong>Laval</strong>, la <strong>Rive-Sud</strong>, la <strong>Rive-Nord</strong>, <strong>Lanaudière</strong> et les <strong>Laurentides</strong>.",
          ],


          a_propos_sous_titre_fondamentales: "Nos valeurs fondamentales",
          a_propos_liste_fondamentales: [
            "<strong>Excellence</strong> : Nous nous efforçons de dépasser les attentes à chaque projet.",
            "<strong>Collaboration</strong> : Travailler en partenariat étroit avec nos clients pour garantir leur satisfaction.",
            "<strong>Durabilité</strong> : Construire des espaces non seulement beaux, mais également conçus pour durer."
          ],


          contacteznous: "Contactez nous dès aujourd’hui",
          contacteznous_details_0: "Vous recherchez un <strong>entrepreneur général à Montréal</strong> fiable et expérimenté ? <strong>Construction Delamain</strong> est là pour vous accompagner dans vos projets de <strong>rénovation résidentielle</strong>, de <strong>construction commerciale</strong>, ou de <strong>restauration patrimoniale</strong>. Faites confiance à une équipe dédiée à transformer vos idées en réalité.",
          contacteznous_details_1: "<strong><1>Contactez-nous dès maintenant</1></strong> pour discuter de vos besoins et découvrir comment nous pouvons vous aider à atteindre vos objectifs.",


        },
        contact: {
          titre: "Contact",
          horaire_ouverture: "Horaire d'ouverture",
          numero_telephone: "Numéro de téléphone",
          adresse: "Adresse",
          prenom: 'Prénom',
          nom_famille: 'Nom de famille',
          emplacement: 'Emplacement du projet (adresse, ville, etc.)',
          date_debut_projet: "Date souhaitée du début des travaux",
          description: 'Description du projet',
          documents: "Je détiens les plans d'architecte",
          permis: "Je détiens les permis municipaux requis",
          le_fichier_zip: "Si vous voulez, vous pouvez téléverser un document (plan, permis etc.) au format PDF, DOC etc., ou encore en regrouper plusieurs dans un fichier ZIP:",
          courriel: {
            adresse: "Adresse courriel",
            erreur: "Une erreur est survenue.",
            succes: "Courriel envoyé.",
            courriel_string: "Courriel",
            format_invalide: "Format d'adresse de courriel invalide",
          },
          bouton_choisir_fichier_a_televerser: "Sélectionnez un fichier",
          choix_de_fichier_a_televerser: "Fichier choisi:",
          champ_requis: "ce champ est obligatoire",
        },
        equipe_partenaire: {
          titre: "Nos partenaires",
        },
        equipe: {
          titre: "Notre équipe",
        },
        projets: {
          titre: "Réalisations",
          trier: "Trier les projets",
          defaut: "Par défaut",
          alphabetique: "Alphabétique",
          alphabetique_inverse: "Alphabétique inversé",
          date: "Chronologique",
          date_inverse: "Chronologique inversé",
          ancien: "Ancien",
          recent: "Récent",
          filtrer: "Filtrer les projets",
          tout: "Tout",
          residentiel: "Résidentiel",
          commercial: "Commercial",
          industriel: "Industriel",
          erreur: {
            aucun_projet_trouve: {
              titre: "Aucun projet n'a été trouvé avec les critères demandés"
            }
          }
        },
        projet: {
          erreur: {
            projet_introuvable: {
              titre: "Erreur: Projet introuvable.",
              description: "Le projet demandé n'a pas pu être trouvé."
            }
          },
          bouton_retour: "Retour aux Réalisations",
        },
        nouvelles: {
          titre: "Nouvelles",
        },
        nouvelle: { // détails d'une nouvelle
          bouton_retour: "Retour aux Nouvelles",
        },
      },
      components: {
        headerbar: {
          titre: "DELAMAIN",
          sous_titre: "Entrepreneur Général",
          accueil: "Accueil",
          projets: "Réalisations",
          services: "Services",
          equipe: "Notre équipe",
          a_propos: "À propos",
          contact: "Contact",
          nouvelles: "Nouvelles",
        },
        footer: {
          titre: "CONSTRUCTION DELAMAIN",
          license_rbq: "{{ license_rbq }}",
          contact: "Contact",
          adresse: {
            rue: "3713 Boul. Saint-Laurent, bureau 203",
            region: "Montréal, Québec",
            code_postal: "H2X 2V7"
          },
          horaire_semaine: "lundi — vendredi: 9:00 – 17:00",
          horaire_fin_de_semaine: "samedi & dimanche: 11:00 – 15:00",
          numero_telephone: "(514) 437-0137",
          courriel: "info@delamain.ca",
        }
      }
    }
  },
  en: {
    translation: {
      global: {
        chargement: "Loading...",
        chargement_erreur: "If the content does not appear in less than 5 to 10 seconds, reload the page.",
        voir_plus: "More",
        erreur: {
          erreur_bd: {
            titre: "Datbase error..."
          }
        }
      },
      pages: {
        accueil: {
          titre: "Home",
          qui_sommes_nous: "Who are we?",

          sous_titre_qui_sommes_nous: "A General Contractor in Montreal – Custom and High-Quality Renovations",
          contenu_qui_sommes_nous_paragraphe_0: "For over <strong>{{depuis}} years</strong>, <strong>Construction Delamain</strong> has been recognized as a trusted <strong>general contractor in Montreal</strong>. Specializing in <strong>residential and commercial renovations</strong>, we have built a strong reputation through our <strong>expertise in design</strong>, <strong>exceptional craftsmanship</strong>, and a <strong>personalized approach tailored to your needs</strong>.",
          contenu_qui_sommes_nous_paragraphe_1: "By choosing <strong>Construction Delamain</strong>, you are selecting an experienced team capable of transforming your ideas into concrete results while respecting your <strong>timelines</strong> and <strong>budgets</strong>.",
          sous_titre_pourquoi_nous: "Why Choose Construction Delamain for Your Renovations in Montreal?",

          contenu_pourquoi_nous_paragraphe_0: "We are committed to:",
          liste_engagements: ["Delivering <strong>exceptional results</strong>that exceed your expectations.",
            "Respecting your <strong>timelines</strong>and <strong>budgets</strong>, no matter the complexity of the project.",
            "Providing <strong>innovative solutions</strong>for unforeseen challenges or scope changes."],

          contenu_pourquoi_nous_paragraphe_1: "In addition to our commitments, we offer comprehensive services to simplify your projects:",
          liste_engagementsplus: ["<strong>Obtaining permits</strong> from the city to ensure your project meets all regulations.",
            "<strong>Custom plans and architectural designs</strong> tailored to your needs and vision."],

          contenu_pourquoi_nous_paragraphe_2: "Trust our team of specialized contractors to deliver <strong>turnkey renovation projects</strong> throughout the <strong>greater Montreal area</strong>, including <strong>Laval</strong>, <strong>Rive-Sud</strong>, <strong>Rive-Nord</strong>, <strong>Lanaudière</strong>, and <strong>Laurentides</strong>.",

          specialites: "Our Renovation Services in Montreal",
          specialites_sous_titre_reno_residentielle: "Residential Renovations",
          specialites_paragraphe_reno_residentielle_0: "We excel in <strong>large-scale residential projects</strong> that require special attention and a high level of finish. Explore our <strong>residential portfolio</strong>, where our <strong>interior and exterior projects</strong> showcase our ability to create the extraordinary.",

          specialites_paragraphe_reno_residentielle_1: "We specialize in:",
          liste_specialitesresidentielles: [
            "<strong>Home extensions</strong>",
            "<strong>Complex residential projects</strong> requiring advanced expertise",
            "<strong>Secure demolition and structural modifications</strong>"
          ],

          specialites_sous_titre_reno_commerciale: "Commercial Renovations",
          specialites_paragraphe_reno_commerciale_0: "Our expertise in <strong>commercial renovations</strong> spans a wide range of projects, including <strong>design/build services</strong> and general construction. We work with businesses of all sizes to create <strong>aesthetic and functional professional spaces</strong>.",

          contacteznous: "Contact Us for a Quote",
          contacteznous_details: "Ready to turn your ideas into reality? <strong>Construction Delamain</strong> is your ideal partner for <strong>high-quality renovations</strong>. <1>Contact us today</1> to discuss your project and get a  <strong>quote</strong>.",
        },
        services: {
          services: "Services",
          services_sous_titre: "Our Services – Turnkey Project Management for Your Renovations",
          services_paragraphe_0: "<strong>Construction Delamain</strong> specializes in comprehensive renovation project management, offering a <strong>turnkey approach</strong> to meet all your needs. Whether it’s transforming a heritage building in Montreal, expanding a home in the suburbs, tackling complex commercial renovations, or managing <strong>post-disaster restoration projects</strong>, we are your trusted partner for exceptional results.",
          services_paragraphe_1: "All our work is insured, guaranteed, and covered by the Régie du bâtiment du Québec ({{ license_rbq }}).",

          services_sous_titre_pourquoi_choisir: "Why Choose Our Turnkey Project Management Services?",
          services_paragraphe_pourquoi_choisir_0: "With <strong>Construction Delamain</strong>, you benefit from support at every stage of the process",
          liste_etapesprocessus: [
            "<strong>Planning</strong>: Assessing your needs, creating custom plans, and preparing accurate quotes.",
            "<strong>Permits</strong>: Handling all administrative procedures with municipalities to ensure compliance.",
            "<strong>Team Coordination</strong>: Supervising work and collaborating with qualified experts (architects, engineers, specialized contractors).",
            "<strong>On-Time and On-Budget Delivery</strong>: Rigorous follow-up to ensure every aspect of the project meets your expectations.",
          ],

          domaines_expertises: "Our Renovation Expertise",

          services_sous_titre_domaines_expertises: "1. Residential Renovations",
          services_paragraphe_domaines_expertises_0: "We transform houses, condos, and chalets into modern and functional spaces.",
          liste_domaines_expertises: [
            "<strong>Home extensions and space reconfiguration</strong>: Creating bright, adaptable spaces that fit your lifestyle.",
            "<strong>High-end renovations</strong>: Custom kitchens and bathrooms with meticulous attention to detail.",
            "<strong>Heritage restoration</strong>: Preserving and modernizing historic properties.",
          ],

          services_sous_titre_reno_commerciale: "2. Commercial Renovations",
          services_paragraphe_reno_commerciale_0: "Our expertise in <strong>commercial renovations</strong> enables us to collaborate with prestigious companies to modernize and optimize their professional spaces. We offer solutions tailored to your specific needs:",
          liste_reno_commerciale: [
            "<strong>Office and commercial space transformations</strong>: Create modern, functional environments for your employees and clients.",
            "<strong>Restaurant and entertainment space renovations</strong>: Bring your concepts to life with bespoke designs.",
            "<strong>Optimization of rental properties and multipurpose spaces</strong>: Enhance the value of your properties through strategic renovations.",
          ],

          services_sous_titre_apres_sinistre: "3. Post-Disaster Restoration Expertise",
          services_paragraphe_apres_sinistre_0: "We understand the urgency and complexity of <strong>post-disaster restoration projects</strong> and have the experience needed to assist you during challenging times.",
          liste_apres_sinistre: [
            "<strong>Water damage restoration</strong>: Structural repairs, dehumidification, and complete renovations.",
            "<strong>Fire damage reconstruction</strong>: Damage assessment, secure demolition, and rebuilding to the highest standards.",
            "<strong>Fast and effective intervention</strong>: Coordination of all aspects, from insurance management to project completion.",
          ],


          clients_prestigieux: "Prestigious Clients",
          services_paragraphe_clients_prestigieux_0: "We’ve had the privilege of working with esteemed clients, including:",
          liste_clients_prestigieux: [
            "<strong>Groupe MAC</strong>",
            "<strong>CGI</strong>",
            "<strong>IRCM</strong>",
            "<strong>Imperial Tobacco</strong>",
            "<strong>Confiserie Mondoux</strong>",
            "<strong>Restaurant Biiru</strong>",
            "<strong>Restaurant NeoTokyo</strong>",
            "<strong>Restaurant Escondite</strong>",
            "<strong>Les Auberges du Cœur</strong>",
            "<strong>Théâtre du Vieux-Terrebonne</strong>",
            "<strong>L’Union Française</strong>",
            "<strong>Centre Scamma</strong>"
          ],


          services_specialises: "Specialized Services",

          services_sous_titre_immobilier: "Management of Complex Real Estate Projects",
          services_paragraphe_immobilier_0: "We are experts in the renovation and expansion of buildings requiring meticulous coordination.",
          liste_immobilier: [
            "<strong>Major building transformations</strong>: Secure demolition, restructuring, and complete modernization.",
            "<strong>Rental property renovations</strong>: Reconfiguring spaces to improve functionality and appeal.",
          ],

          services_sous_titre_agrandissement: "Suburban Home Expansions",
          services_paragraphe_agrandissement_0: "Looking to add space to your property? Whether it’s a single-family home or a building in the suburbs, we offer tailored solutions:",
          liste_agrandissement: [
            "Extensions of main living areas.",
            "Addition of floors or extra units.",
            "Integration of harmonious outdoor layouts.",
          ],

          services_sous_titre_gestion_administrative: "Administrative and Technical Management",
          liste_gestion_administrative: [
            "<strong>Permit acquisition</strong>: Complete management to ensure compliance with construction standards.",
            "<strong>Architectural design</strong>: Creating plans tailored to your needs and local regulations.",
            "<strong>Advanced technical solutions</strong>: Structural modifications, French drain installations, and waterproofing work.",
          ],





          services_sur_mesure: "Tailored Services for Your Projects",
          services_paragraphe_services_sur_mesure_0: "At <strong>Construction Delamain</strong>, we are proud to serve the <strong>Greater Montreal Area</strong>, specializing in projects located in <strong>Ville Mont-Royal (VMR)</strong>, <strong>Westmount</strong>, and <strong>Le Plateau Mont-Royal</strong>. Whether it’s restoring a historic home in <strong>Westmount</strong>, modernizing a condo in <strong>Le Plateau Mont-Royal</strong>, or expanding a property in <strong>VMR</strong>, our team delivers high-quality renovations designed to enhance the beauty, functionality, and value of your space.",
          services_paragraphe_services_sur_mesure_1: "Our expertise also extends to <strong>Laval</strong>, the <strong>South Shore</strong>, the <strong>North Shore</strong>, <strong>Lanaudière</strong>, and the <strong>Laurentians</strong>, ensuring comprehensive coverage across the region.",



          contacteznous: "Contact Us Today",
          contacteznous_details: "Entrust your projects to <strong>Construction Delamain</strong> for a seamless experience and results that exceed your expectations. <1>Contact us today</1> for a <strong>quote</strong> and discover how we can bring your ideas to life.",
        },
        apropos: {
          a_propos: "About Us",

          a_propos_sous_titre: "Construction Delamain – Your Trusted Partner in Renovation and Construction in Montreal",
          a_propos_paragraphe_0: "Since {{ annee_fondation }}, <strong>Construction Delamain</strong> has established itself as an award-winning <strong>general contracting company</strong>, meeting the needs of the <strong>residential</strong> and <strong>commercial sectors</strong> across the <strong>Greater Montreal Area</strong>. With 90% of our projects stemming from recommendations and loyal clients, we have built a solid reputation based on quality, trust, and client satisfaction.",
          a_propos_paragraphe_1: "Whether it’s <strong>large-scale residential renovations</strong>, transforming commercial buildings, or tackling complex projects, we are committed to delivering exceptional results while fostering lasting relationships with our clients.",


          a_propos_sous_titre_respectee_reconnue: "A Respected and Recognized Construction Company",
          a_propos_paragraphe_respectee_reconnue_0: "At <strong>Construction Delamain</strong>, we don’t just create exceptional spaces; we build trust. Our approach is based on:",
          a_propos_liste_respectee_reconnue: [
            "<strong>Listening attentively</strong> to your needs.",
            "<strong>Collaborating closely</strong> with you throughout your project.</li>",
            "Providing <strong>custom solutions</strong> tailored to your expectations and budget.",
          ],
          a_propos_paragraphe_respectee_reconnue_1: "Our commitment to <strong>integrity</strong>, <strong>professionalism</strong>, and <strong>transparency</strong> is at the heart of everything we do.",






          a_propos_sous_titre_notre_equipe: "Our Team of Passionate Experts",
          a_propos_paragraphe_notre_equipe_0: "For over {{ depuis }} years, <strong>Antonie and his team</strong> have combined their talents in design and construction to offer a unique approach to <strong>general contracting</strong>.",



          a_propos_sous_titre_antonie: "Antonie Bordeleau – Vision and Energy",
          a_propos_paragraphe_antonie_0: "With optimism and open-mindedness, Antonie turns obstacles into opportunities. His strengths include:",
          a_propos_liste_antonie: [
            "<strong>Attentive listening</strong> to ensure your needs remain the central focus of the project.",
            "<strong>Creative solutions</strong> tailored to even the most complex challenges.",
            "<strong>Boundless energy</strong> and a calm approach that inspires confidence."
          ],



          a_propos_sous_titre_polyvalente: "A Versatile and Dedicated Team",
          a_propos_paragraphe_polyvalente_0: "The <strong>Construction Delamain</strong> team is made up of qualified and passionate experts. Together, they:",
          a_propos_liste_polyvalente: [
            "Bring a <strong>meticulous attention to detail</strong> at every stage of the project.",
            "Master multitasking to ensure a <strong>seamless and uncompromised execution</strong>.",
            "Work with <strong>integrity</strong> and <strong>transparency</strong> to guarantee your satisfaction."
          ],



          a_propos_sous_titre_philosophie: "The Delamain Philosophy",
          a_propos_paragraphe_philosophie_0: "The values shared by Antonie and his team form the foundation of <strong>Construction Delamain</strong>:",
          a_propos_liste_philosophie: [
            "<strong>Integrity</strong> in our practices.",
            "<strong>Professionalism</strong> in every detail.",
            "<strong>Transparency</strong> with our clients, partners, and teams."
          ],

          a_propos_sous_titre_pourquoi: "Why Choose Construction Delamain?",
          a_propos_liste_pourquoi: [
            "<strong>Proven Expertise</strong>: Over {{ depuis }} years of experience in the residential and commercial sectors.",
            "<strong>A Reputation Built on Trust</strong>: 90% of our projects come from recommendations or loyal clients.",
            "<strong>Tailored Projects</strong>: Each renovation or construction project is customized to meet your unique needs.",
            "<strong>Regional Coverage</strong>: Serving the <strong>Greater Montreal Area</strong>, including <strong>Laval</strong>, the <strong>South Shore</strong>, the <strong>North Shore</strong>, <strong>Lanaudière</strong>, and the <strong>Laurentians</strong>."
          ],



          a_propos_sous_titre_fondamentales: "Our Core Values",
          a_propos_liste_fondamentales: [
            "<strong>Excellence</strong>: We strive to exceed expectations on every project.",
            "<strong>Collaboration</strong>: Working in close partnership with our clients to ensure their satisfaction.",
            "<strong>Durability</strong>: Building spaces that are not only beautiful but designed to last."
          ],


          contacteznous: "Contact Construction Delamain Today",
          contacteznous_details_0: "Are you looking for a reliable and experienced <strong>general contractor in Montreal</strong>? <strong>Construction Delamain</strong> is here to assist you with your <strong>residential renovations</strong>, <strong>commercial construction</strong>, or <strong>heritage restoration</strong> projects. Trust a team dedicated to turning your ideas into reality.",
          contacteznous_details_1: "<strong><1>Contact us today</1></strong> to discuss your needs and discover how we can help you achieve your goals.",

        },
        contact: {
          titre: "Contact",
          horaire_ouverture: "Opening hours",
          numero_telephone: "Phone number",
          adresse: "Address",
          prenom: "First name",
          nom_famille: "Last name",
          emplacement: "Project location (address, city, etc.)",
          date_debut_projet: "Desired start date of the project",
          description: "Project description",
          documents: "I have architecture plans",
          permis: "I have the required municipal permits",
          le_fichier_zip: "If you wish, you can upload a document (plan, permit, etc.) in PDF, DOC, etc. format, or group several in a ZIP file:",
          courriel: {

            adresse: "Email address",
            erreur: "An error has occurred.",
            succes: "Email sent.",
            courriel_string: "Email",
            format_invalide: "Invalid email address format",

          },
          bouton_choisir_fichier_a_televerser: "Select a file",
          choix_de_fichier_a_televerser: "Chosen file:",
          champ_requis: "This field is required",
        },
        equipe_partenaire: {
          titre: "Our partners",
        },
        equipe: {
          titre: "Our team",
        },
        projets: {
          titre: "Projects",
          trier: "Sort projects",
          defaut: "Default",
          alphabetique: "Alphabetic",
          alphabetique_inverse: "Reverse alphabetic",
          date: "Date",
          date_inverse: "Reverse date",
          ancien: "Old",
          recent: "Recent",
          filtrer: "Filter projects",
          tout: "All",
          residentiel: "Residential",
          commercial: "Commercial",
          industriel: "Industrial",
          erreur: {
            aucun_projet_trouve: {
              titre: "No project was found with the requested criteria."
            }
          }
        },
        projet: {
          erreur: {
            projet_introuvable: {
              titre: "Error: Project not found.",
              description: "The requested project could not be found."
            }
          },
          bouton_retour: "Back to the Projects",
        },
        nouvelles: {
          titre: "News",
        },
        nouvelle: {
          bouton_retour: "Back to the News",
        },
      },
      components: {
        headerbar: {
          titre: "DELAMAIN",
          sous_titre: "General Contractor",
          accueil: "Home",
          projets: "Projects",
          services: "Services",
          equipe: "Our team",
          a_propos: "About us",
          contact: "Contact",
          nouvelles: "News",
        },
        footer: {
          titre: "CONSTRUCTION DELAMAIN",
          license_rbq: "{{ license_rbq }}",
          contact: "Contact",
          adresse: {
            rue: "3713 Boul. Saint-Laurent, bureau 203",
            region: "Montréal, Québec",
            code_postal: "H2X 2V7"
          },
          horaire_semaine: "Monday — Friday: 9:00 – 17:00",
          horaire_fin_de_semaine: "Saturday & Sunday: 11:00 – 15:00",
          numero_telephone: "(514) 437-0137",
          courriel: "info@delamain.ca"
        }
      }
    }
  },
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: {
      // Définit l'ordre de détection pour donner la priorité à la langue du navigateur de l'utilisateur
      // order: ['navigator'],
      order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
    },
    resources,
    fallbackLng: "fr",
  });

export default i18n;
