import { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { db, auth } from '../../database';
import TitreCouleur from '../../components/TitreCouleur';
import { useAuthState } from 'react-firebase-hooks/auth';
import EcranChargement from '../../components/EcranChargement';
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';
import { collection } from 'firebase/firestore';

function AdminDashboard() {
    const navigate = useNavigate();

    const [user, auth_loading ] = useAuthState(auth);

    const [firestore_values, firestore_loading ] = useCollectionDataOnce(
        collection(db, 'utilisateurs')
    );

    const [loginStatus, setLoginStatus] = useState(false);

    // verifie si l'utilisateur est autorise dans la DB
    useEffect(() => {
        firestore_values?.forEach(element => {
            if (user?.uid === element.auth_userid) {
                setLoginStatus(true)
            }
        });
    }, [firestore_values, user?.uid]);

    if (auth_loading || firestore_loading) {
        return <EcranChargement />
    }

    if (!auth_loading && !user) {
        navigate("/admin/login")
        return (
            <div>
                <h2>Vous devez être connecté pour voir ceci.</h2>
            </div>
        )
    }

    if (user && !firestore_loading && !loginStatus) {
        return (
            <div>
                <h2>Vous n'êtes pas autorisé à voir ceci.</h2>
            </div>
        )
    }



    return (
        <div>
            <TitreCouleur texte='Tableau de bord' taille={3.2} />
            {/* <button className='global_button' style={{ background: '#d70000', color: 'white' }} onClick={logOutButton}>Déconnexion</button> */}

            <div className='global_grid'>
                <div className='global_card' style={{ flexDirection: 'column' }}>
                    <h3>Gestion des 'Projets'</h3>

                    <Link className='global_navigation_link' to={'/admin/projets/'}>
                        <button className="global_button">
                            Go!
                        </button>
                    </Link>
                </div>

                <div className='global_card' style={{ flexDirection: 'column' }}>
                    <h3>Gestion de 'Notre Équipe'</h3>

                    <Link className='global_navigation_link' to={'/admin/equipe/'}>
                        <button className="global_button">
                            Go!
                        </button>
                    </Link>
                </div>

                <div className='global_card' style={{ flexDirection: 'column' }}>
                    <h3>Gestion des 'Nouvelles'</h3>

                    <Link className='global_navigation_link' to={'/admin/nouvelles/'}>
                        <button className="global_button">
                            Go!
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default AdminDashboard;
