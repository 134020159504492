import style from '../styles/projet_full_screen.module.css'
import TitreCouleur from '../components/TitreCouleur';
import { doc } from 'firebase/firestore';
import { db } from '../database';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import EcranChargement from '../components/EcranChargement';
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore';

function NouvelleFullScreen() {
    let { id } = useParams();
    const { t } = useTranslation();

    const [value, loading, error] = useDocumentDataOnce(
        doc(db, 'nouvelles', id + '')
    );

    console.log(value)

    if (loading && !value) {
        return (
            <div>
                <EcranChargement />
            </div>
        )
    }

    if (error) {
        return (
            <div>
                <h1>{t('global.erreur.erreur_bd.titre')}</h1>
                ---
                <h3>{error.name} - {error.code}</h3>
                <p>{error.message}</p>
            </div>
        )
    }

    if (!value || !id) {
        return (
            <div>
                <h1>{t('pages.projet.erreur.projet_introuvable.titre')}</h1>
                <p>{t('pages.projet.erreur.projet_introuvable.description')}</p>
            </div>
        )
    }

    return (
        <div className={style.container}>
            <div className={style.container_contenu}>
                <TitreCouleur texte={value.titre} taille={3.2} position='left'/>
                {<small style={{ color: "grey" }}   >[Publication: {new Date(value.date_publication.toDate().toISOString()).toLocaleString('UTC', { month: 'long', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' })}]</small>}
                <p className={style.sous_titre}>{value.sous_titre}</p>
                <p className={style.content} dangerouslySetInnerHTML={{ __html: value.contenu.replace(/\n/g, '<br/>') }} />
                <br/>
                <br/>
                <br/>
                <center>
                    <Link className='global_navigation_link' to={'/nouvelles/' }>
                        <button className="global_button">
                            {t('pages.nouvelle.bouton_retour')}
                        </button>
                    </Link>
                </center>
            </div>
        </div>
    );
}

export default NouvelleFullScreen;
