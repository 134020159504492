import { Trans, useTranslation } from 'react-i18next';
import TitreCouleur from '../components/TitreCouleur';
import styles from '../styles/a_propos.module.css';
import * as var_glob from '../variables.mjs';

function APropos() {
  const { t } = useTranslation();

  return (
    <div>

      <div className={styles.contenu_flex}>

        <TitreCouleur texte={t('pages.apropos.a_propos')} taille={3.2} />
        <div className={styles.categorie}>

          <div className={styles.sous_categorie}>
            <h2 className={`${styles.sous_titre || ''} sous_titre`}>
              <Trans i18nKey="pages.apropos.a_propos_sous_titre" />
            </h2>
          </div>
          <p className={styles.content}>
            <Trans i18nKey="pages.apropos.a_propos_paragraphe_0" values={{ annee_fondation: var_glob._dateFondation.getFullYear() }} />
          </p>

          <p className={styles.content}>
            <Trans i18nKey="pages.apropos.a_propos_paragraphe_1" />
          </p>
        </div>


        <div className={styles.sous_categorie}>
          <h2 className={`${styles.sous_titre || ''} sous_titre`}>
            <Trans i18nKey="pages.apropos.a_propos_sous_titre_respectee_reconnue" />
          </h2>
        </div>

        <p className={styles.content}>
          <Trans i18nKey="pages.apropos.a_propos_paragraphe_respectee_reconnue_0" />
        </p>

        <Trans>
          <ul>
            {(t('pages.apropos.a_propos_liste_respectee_reconnue', { returnObjects: true }) as string[]).map((puce: string, index: number) => (
              <li key={index}>{puce}</li>
            ))}
          </ul>
        </Trans>

        <p className={styles.content}>
          <Trans i18nKey="pages.apropos.a_propos_paragraphe_respectee_reconnue_1" />
        </p>


        <TitreCouleur texte={t('pages.apropos.a_propos_sous_titre_notre_equipe')} taille={2.1} />
        <div className={styles.categorie}>

          <p className={styles.content}>
            <Trans i18nKey="pages.apropos.a_propos_paragraphe_notre_equipe_0" values={{ depuis: var_glob._anneesOperation }}></Trans>
          </p>

          <div className={styles.sous_categorie}>
            <h2 className={`${styles.sous_titre || ''} sous_titre`}>
              <Trans i18nKey="pages.apropos.a_propos_sous_titre_antonie" />
            </h2>
          </div>

          <p className={styles.content}>
            <Trans i18nKey="pages.apropos.a_propos_paragraphe_antonie_0" />
          </p>

          <Trans>
            <ul>
              {(t('pages.apropos.a_propos_liste_antonie', { returnObjects: true }) as string[]).map((puce: string, index: number) => (
                <li key={index}>{puce}</li>
              ))}
            </ul>
          </Trans>


          <div className={styles.sous_categorie}>
            <h2 className={`${styles.sous_titre || ''} sous_titre`}>
              <Trans i18nKey="pages.apropos.a_propos_sous_titre_polyvalente" />
            </h2>
          </div>

          <p className={styles.content}>
            <Trans i18nKey="pages.apropos.a_propos_paragraphe_polyvalente_0" />
          </p>

          <Trans>
            <ul>
              {(t('pages.apropos.a_propos_liste_polyvalente', { returnObjects: true }) as string[]).map((puce: string, index: number) => (
                <li key={index}>{puce}</li>
              ))}
            </ul>
          </Trans>


          <TitreCouleur texte={t('pages.apropos.a_propos_sous_titre_philosophie')} taille={2.1} />

          <p className={styles.content}>
            <Trans i18nKey="pages.apropos.a_propos_paragraphe_philosophie_0" />
          </p>

          <Trans>
            <ul>
              {(t('pages.apropos.a_propos_liste_philosophie', { returnObjects: true }) as string[]).map((puce: string, index: number) => (
                <li key={index}>{puce}</li>
              ))}
            </ul>
          </Trans>

          <div className={styles.sous_categorie}>
            <h2 className={`${styles.sous_titre || ''} sous_titre`}>
              <Trans i18nKey="pages.apropos.a_propos_sous_titre_pourquoi" />
            </h2>
          </div>

          <Trans>
            <ul>

              {(t('pages.apropos.a_propos_liste_pourquoi', { returnObjects: true, depuis: var_glob._anneesOperation }) as string[]).map((puce: string, index: number) => (
                <li key={index}>{puce}</li>
              ))}
            </ul>
          </Trans>

          <div className={styles.sous_categorie}>
            <h2 className={`${styles.sous_titre || ''} sous_titre`}>
              <Trans i18nKey="pages.apropos.a_propos_sous_titre_fondamentales" />
            </h2>
          </div>

          <Trans>
            <ul>

              {(t('pages.apropos.a_propos_liste_fondamentales', { returnObjects: true }) as string[]).map((puce: string, index: number) => (
                <li key={index}>{puce}</li>
              ))}
            </ul>
          </Trans>
        </div>




        <TitreCouleur texte={t('pages.apropos.contacteznous')} taille={2.1} />
          <div className={styles.categorie}>
            <p className={styles.content}>
              <Trans
                i18nKey="pages.apropos.contacteznous_details_0"
                components={{ 1: <a href='/contact'>Contactez-nous</a> }}
              />
            </p>
            <p className={styles.content}>
              <Trans
                i18nKey="pages.apropos.contacteznous_details_1"
                components={{ 1: <a href='/contact'>Contactez-nous</a> }}
              />
            </p>
          </div>
      </div>


    </div>
  );
}

export default APropos;
