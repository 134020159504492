import { collection } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';
import { useTranslation } from 'react-i18next';
import EcranChargement from '../components/EcranChargement';
import ProjetsGridItem from '../components/ProjetsGridItem';
import TitreCouleur from '../components/TitreCouleur';
import { db } from '../database';
import styles from '../styles/projets.module.css';

enum sortEnum {
    defaut,
    alpha,
    alpha_reverse,
    date,
    date_reverse
}

enum filterEnum {
    tout
    , residentiel
    , commercial
}

function sortResults(sort_method: sortEnum, values: any) {

    const parseDate = (dateString: string) => {
        return new Date(dateString);
    };

    switch (sort_method) {
        case sortEnum.alpha_reverse:
            values?.sort((a: any, b: any) => b.titre.localeCompare(a.titre));
            break;

        case sortEnum.alpha:
            values?.sort((a: any, b: any) => a.titre.localeCompare(b.titre));
            break;

        case sortEnum.defaut:
        case sortEnum.date:
        case sortEnum.date_reverse:
            values?.sort((a: any, b: any) => {
                // console.log(a.date_fin_des_travaux, a.date_ajout_projet);
                const dateA = a.date_fin_des_travaux ? parseDate(a.date_fin_des_travaux).getTime() : parseDate(a.date_ajout_projet).getTime();
                const dateB = b.date_fin_des_travaux ? parseDate(b.date_fin_des_travaux).getTime() : parseDate(b.date_ajout_projet).getTime();
                const currentDate = new Date().getTime();
                const validDateA = isNaN(dateA) ? currentDate : dateA;
                const validDateB = isNaN(dateB) ? currentDate : dateB;
                return sort_method === sortEnum.date ? validDateA - validDateB : validDateB - validDateA;
            });
            break;

        default:
            break;
    }

    return values;
}

function filterResults(filter: filterEnum, values: any) {
    let result: any;

    switch (filter) {
        case filterEnum.tout:
            result = values;
            break;

        case filterEnum.residentiel:
            result = values.filter((item: any) => item.categorie === 'Résidentiel');
            break;

        case filterEnum.commercial:
            result = values.filter((item: any) => item.categorie === 'Commercial');
            break;

        default:
            break;
    }

    return result;
}

function Projets() {
    const [values, loading, error] = useCollectionDataOnce(
        collection(db, 'projets')
    );
    const [sortMethod, setSortMethod] = useState(sortEnum.defaut);
    const [filter, setFilter] = useState(filterEnum.tout)
    const [projets, setProjets] = useState<any[]>([])

    const { t } = useTranslation();

    useEffect(() => {
        if (values) {
            let filteredResults = filterResults(filter, values)
            let sortedResults = sortResults(sortMethod, filteredResults)
            setProjets([...sortedResults])
        }
    }, [values, filter, sortMethod]);

    if (loading) {
        return (
            <EcranChargement />
        )
    }

    if (error) {
        return (
            <div style={{ textAlign: 'center' }}>
                <h1>{t('global.erreur.erreur_bd.titre')}</h1>
                ---
                <h3>{error.name} - {error.code}</h3>
                <p>{error.message}</p>
            </div>
        )
    }

    function ComposantListeProjets() {

        if (projets?.length === 0) {
            return (
                <div>
                    <h2 style={{ textAlign: 'center' }}>{t('pages.projets.erreur.aucun_projet_trouve.titre')}</h2>
                </div>
            )
        }

        return (
            <div className={styles.grid}>
                {
                    projets?.map(({ id, titre, description, description_courte, adresse, images, categorie, sous_categorie }: any) => {
                        return (
                            <ProjetsGridItem
                                key={id}
                                id={id}
                                titre={titre}
                                description={description}
                                description_courte={description_courte}
                                adresse={adresse}
                                images={images}
                                categorie={categorie}
                                sous_categorie={sous_categorie} />
                        )
                    })}
            </div>
        )
    }

    return (
        <div>
            <TitreCouleur texte={t('pages.projets.titre')} taille={3.2} />

            <div className={styles.container_recherche}>
                <form className={styles.container_tri}>
                    <label>{t('pages.projets.trier')}</label><br />
                    <select className={styles.select_dropdown} onChange={(event) => {
                        setSortMethod(parseInt(event.target.value));
                    }}
                        defaultValue={sortMethod}>
                        <option value="0">{t('pages.projets.defaut')}</option>
                        <option value="1">{t('pages.projets.alphabetique')} (A  {'\u279E'}  Z)</option>
                        <option value="2">{t('pages.projets.alphabetique_inverse')} (Z  {'\u279E'}  A)</option>
                        <option value="3">{t('pages.projets.date')} ({t('pages.projets.ancien')}  {'\u279E'}  {t('pages.projets.recent')})</option>
                        <option value="4">{t('pages.projets.date_inverse')} ({t('pages.projets.recent')}  {'\u279E'}  {t('pages.projets.ancien')})</option>
                    </select>
                </form>


                {/* classe du form changee a celle du tri car le tri a ete retire */}
                {/* le tri etait a gauche, donc on utilise sa classe de style a la place */}
                {/*  <form className={styles.container_tri}>
                    <label>{t('pages.projets.filtrer')}</label><br/>
                    <select className={styles.select_dropdown} onChange={(event) => {
                        setFilter(parseInt(event.target.value));
                    }}
                        defaultValue={filter}>
                        <option value="0">{t('pages.projets.tout')}</option>
                        <option value="1">{t('pages.projets.residentiel')}</option>
                        <option value="2">{t('pages.projets.commercial')}</option>
                    </select>
                </form> */}

            </div>

            <ComposantListeProjets />

        </div>
    );
}

export default Projets;
