import { collection, doc, updateDoc } from 'firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollectionDataOnce, useDocumentDataOnce } from 'react-firebase-hooks/firestore';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from "react-router-dom";
import EcranChargement from '../../components/EcranChargement';
import TitreCouleur from '../../components/TitreCouleur';
import { auth, db } from '../../database';

import toast from 'react-hot-toast';

function AdminModifierProjet() {
    let { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [user, auth_loading] = useAuthState(auth);

    const [firestore_values, firestore_loading] = useCollectionDataOnce(
        collection(db, 'utilisateurs')
    );

    const [projets_values, projets_loading, projets_error] = useDocumentDataOnce(
        doc(db, 'projets', id + '')
    );

    const [loginStatus, setLoginStatus] = useState(false);

    // valeurs pour le formulaire
    const [titreData, setTitre] = useState('');
    const [adresseData, setAdresse] = useState('');
    const [categorieData, setCategorie] = useState('');
    const [dateFinData, setDateFin] = useState('');
    const [descriptionData, setDescription] = useState('');
    const [descriptionCourteData, setDescriptionCourte] = useState('');
    const [images, setImages] = useState<string[]>([]);
    const [selectedFiles, setSelectedFiles] = useState<FileList>();

    const storage = getStorage();

    // verifie si l'utilisateur est autorise dans la DB
    useEffect(() => {
        firestore_values?.forEach(element => {
            if (user?.uid === element.auth_userid) {
                setLoginStatus(true)
            }
        });
    }, [firestore_values, user?.uid]);

    useEffect(() => {
        setTitre(projets_values?.titre);
        setAdresse(projets_values?.adresse)
        setDescription(projets_values?.description);
        setDescriptionCourte(projets_values?.description_courte);
        setCategorie(projets_values?.categorie);
        setDateFin(projets_values?.date_fin_des_travaux);
        setImages(projets_values?.images || []);
    }, [projets_values]);

    if (auth_loading || firestore_loading || projets_loading) {
        return <EcranChargement />
    }

    if (!auth_loading && !user) {
        navigate("/admin/login")
        return (
            <div>
                <h2>Vous devez être connecté pour voir ceci.</h2>
            </div>
        )
    }

    if (user && !firestore_loading && !loginStatus) {
        return (
            <div>
                <h2>Vous n'êtes pas autorisé à voir ceci.</h2>
            </div>
        )
    }

    if (projets_error) {
        return (
            <div style={{ textAlign: 'center' }}>
                <h1>{t('global.erreur.erreur_bd.titre')}</h1>
                ---
                <h3>{projets_error.name} - {projets_error.code}</h3>
                <p>{projets_error.message}</p>
            </div>
        )
    }

    async function formSubmit() {
        console.log("Starting formSubmit");
        const dateMaj = new Date().toISOString();
        const newImages = await uploadFichiers();
        console.log("New Images:", newImages);
        const allImages = [...images, ...newImages];
        console.log("All Images:", allImages);

        await updateDoc(doc(db, "projets", id + ''), {
            titre: titreData,
            adresse: adresseData,
            description: descriptionData,
            description_courte: descriptionCourteData,
            date_fin_des_travaux: dateFinData ?? "",
            categorie: categorieData,
            images: allImages,
            date_maj_projet: dateMaj
        }).then(() => {
            toast.success('Informations enregistrées.')
            navigate('/admin/projets')
        }).catch((error) => {
            console.error("Error updating document:", error);
            toast.error('Une erreur est survenue. Veuillez réessayer plus tard.')
        })

        await ajouterImagesAuProjet(newImages);
    }

    async function ajouterImagesAuProjet(newImages: string[]) {
        console.log("AjouterImagesAuProjet", newImages);

        const allImages = [...images, ...newImages];
        await updateDoc(doc(db, "projets", id + ''), {
            images: allImages
        }).then(() => {
            toast.success('Images ajoutées.')
        }).catch((error) => {
            console.error("Error updating document with images:", error);
            toast.error('Une erreur est survenue. Veuillez réessayer plus tard: ' + error)
        })
    }

    async function uploadFichiers(): Promise<string[]> {
        console.log("Starting uploadFichiers");
        let urls: string[] = [];

        if (selectedFiles) {
            const uploadPromises: Promise<string>[] = [];

            for (let i = 0; i < selectedFiles.length; i++) {
                const titreImage = selectedFiles[i].size + selectedFiles[i].name;
                const imageRef = ref(storage, `/projets/${id}/${titreImage}`);

                const uploadPromise = uploadBytes(imageRef, selectedFiles[i])
                    .then((snapshot) => getDownloadURL(snapshot.ref))
                    .then((downloadURL) => {
                        const customURL = `${downloadURL}&monNomImage=${encodeURIComponent(selectedFiles[i].name)}`;
                        return customURL;
                    });

                uploadPromises.push(uploadPromise);
            }

            try {
                urls = await Promise.all(uploadPromises);
                console.log("Uploaded URLs with custom parameters:", urls);
            } catch (error) {
                console.error("Error uploading files:", error);
                toast.error('Une erreur est survenue. Veuillez réessayer plus tard: ' + error)
            }
        }

        console.log("Final URLs:", urls);
        return urls;
    }

    // const handleImageChange = (index: number, value: string) => {
    //     const newImages = [...images];
    //     newImages[index] = value;
    //     setImages(newImages);
    // };

    // const addImageField = () => {
    //     setImages([...images, '']);
    // };

    const removeImageField = (index: number) => {
        const newImages = images.filter((_, i) => i !== index);
        setImages(newImages);
    };

    const moveImageUp = (index: number) => {
        if (index > 0) {
            const newImages = [...images];
            const temp = newImages[index - 1];
            newImages[index - 1] = newImages[index];
            newImages[index] = temp;
            setImages(newImages);
        }
    };

    const moveImageDown = (index: number) => {
        if (index < images.length - 1) {
            const newImages = [...images];
            const temp = newImages[index + 1];
            newImages[index + 1] = newImages[index];
            newImages[index] = temp;
            setImages(newImages);
        }
    };

    // // function extractFileName(url: string) {
    // const extractFileName = (url: string) => {
    //     const urlObj = new URL(url);
    //     const customName = urlObj.searchParams.get('monNomImage');
    //     if (customName) {
    //         return customName;
    //     }
    //     const regex = /\/(?:\d{6})?([^\/]+\.*)\?/;
    //     const match = decodeURIComponent(url).match(regex);
    //     return match ? match[1] : url;
    // }

    return (
        <div>
            <TitreCouleur texte='Modifier un projet' taille={3.2} />

            <div className='global_form center'>

                <label htmlFor="titre" className='global_form_label'>Titre</label>
                <input
                    className='global_text_field'
                    type="text"
                    // value={titre}
                    defaultValue={projets_values?.titre}
                    onChange={(e) => setTitre(e.target.value)}
                />

                <label htmlFor="adresse" className='global_form_label'>Adresse/emplacement</label>
                <input
                    className='global_text_field'
                    type="text"
                    // value={descriptionCourte}
                    defaultValue={projets_values?.adresse}
                    onChange={(e) => setAdresse(e.target.value)}
                />

                <label htmlFor="descriptionCourte" className='global_form_label'>Description courte</label>
                <input
                    className='global_text_field'
                    type="text"
                    // value={descriptionCourte}
                    defaultValue={projets_values?.description_courte}
                    onChange={(e) => setDescriptionCourte(e.target.value)}
                />

                <label htmlFor="description" className='global_form_label'>Description longue</label>
                <textarea
                    className='global_text_field global_text_area'
                    // value={description}
                    defaultValue={projets_values?.description}
                    onChange={(e) => setDescription(e.target.value)} />

                <label htmlFor="dateFin" className='global_form_label'>Date de fin du projet</label>
                <input
                    className='global_text_field'
                    type="date"
                    // value={dateFin}
                    defaultValue={projets_values?.date_fin_des_travaux}
                    onChange={(e) => setDateFin(e.target.value)}
                />

                <label htmlFor="categorie" className='global_form_label'>Catégorie</label>
                <select
                    className='global_text_field'
                    id="categorie"
                    name='categorie'
                    // value={description}
                    defaultValue={projets_values?.categorie}
                    onChange={(e) => setCategorie(e.target.value)}>
                    <option value="Résidentiel">Résidentiel</option>
                    <option value="Commercial">Commercial</option>
                    <option value="Industriel">Industriel</option>
                </select>

                <label htmlFor="images" className='global_form_label'>Images</label>
                {images.map((url, index) => (
                    <div key={index} className='thumbnail-field'>
                        <img
                            src={url}
                            alt={`Thumbnail ${index}`}
                            className='thumbnail-image'
                            style={{ width: '100%', height: '100%' }} />
                        <button
                            className="global_button"
                            style={{ background: 'linear-gradient(143deg, #ff0000 0%, #be0000 100%)', color: "white", fontSize: '0.8em', padding: '0.5em 1em' }}
                            onClick={() => {
                                if (window.confirm('Êtes-vous sûr de vouloir supprimer cette image ?')) {
                                    removeImageField(index);
                                }
                            }}>
                            Supprimer </button> &nbsp;
                        <button
                            className="global_button"
                            style={{ background: 'linear-gradient(143deg,rgba(10, 212, 10, 0.71) 0%,rgb(10, 161, 10) 100%)', color: "white", fontSize: '0.8em', padding: '0.5em 1em' }}
                            onClick={() => moveImageUp(index)}
                            disabled={index === 0}>
                            Monter </button> &nbsp;
                        <button
                            className="global_button"
                            style={{ background: 'linear-gradient(143deg, #0000ff 0%, #0000be 100%)', color: "white", fontSize: '0.8em', padding: '0.5em 1em' }}
                            onClick={() => moveImageDown(index)}
                            disabled={index === images.length - 1}>
                            Descendre </button> &nbsp;
                    </div>
                ))}

                <label htmlFor="fileUpload" className='global_form_label'>Ajouter des fichiers</label>
                <input
                    id="fileUpload"
                    type="file"
                    multiple
                    onChange={(e) => {
                        const file = e.target.files ? e.target.files : undefined;
                        setSelectedFiles(file);
                    }}
                />

                <button className='global_button' onClick={async () => await formSubmit()}>
                    Enregistrer
                </button>
            </div>
        </div>
    );
}

export default AdminModifierProjet;
