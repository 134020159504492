import { doc } from 'firebase/firestore';
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import EcranChargement from '../components/EcranChargement';
import ProjetFullScreen from '../components/ProjetFullScreen';
import { db } from '../database';

function Projet() {
    let { id } = useParams();
    const { t } = useTranslation();

    const [value, loading, error ] = useDocumentDataOnce(
        doc(db, 'projets', id + '')
    );

    if (loading && !value) {
        return (
            <div>
                <EcranChargement />
            </div>
        )
    }

    if (error) {
        return (
            <div>
                <h1>{t('global.erreur.erreur_bd.titre')}</h1>
                ---
                <h3>{error.name} - {error.code}</h3>
                <p>{error.message}</p>
            </div>
        )
    }

    if (!value || !id) {
        return (
            <div>
                <h1>{t('pages.projet.erreur.projet_introuvable.titre')}</h1>
                <p>{t('pages.projet.erreur.projet_introuvable.description')}</p>
            </div>
        )
    }

    return (
        <div>
            <ProjetFullScreen
                titre={value.titre}
                description={value.description}
                description_courte={value.description_courte}
                adresse={value.adresse}
                images={value.images}
                categorie={value.categorie}
                sous_categorie={value.sous_categorie} />
        </div>
    );
}

export default Projet;
