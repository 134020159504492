import { useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { RotatingLines } from 'react-loader-spinner';
import TitreCouleur from '../components/TitreCouleur';
import styles from '../styles/contact.module.css';

import { FileUploader } from "../components/FileUploader.js";

import * as var_glob from '../variables.mjs';

function Contact() {
  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  const [prenom, setPrenom] = useState('');
  const [prenomError, setPrenomError] = useState('');

  const [nom, setNom] = useState('');
  const [nomError, setNomError] = useState('');

  const [description, setDescription] = useState('');
  const [descriptionError, setdescriptionError] = useState('');

  const [telephone, setTelephone] = useState('');
  const [emplacement, setEmplacement] = useState('');
  const [dateDebut, setDateDebut] = useState('');
  const [isDocVille, setIsDocVille] = useState(false);
  const [isDocArchitecte, setIsDocArchitecte] = useState(false);

  const [loading, setLoading] = useState(false)

  // TODO trouver pourquoi en PROD (en dev ça fonctionne apparemmment) cette variable (REACT_APP_SENDMAIL_PUBLIC_HOST) est indéfinie
  const sendmail_public_host = process.env.REACT_APP_SENDMAIL_PUBLIC_HOST || 'www.delamain.ca'


  function validateForm() {
    let isValid = true;

    // Validation du courriel
    if (!email) {
      setEmailError(t('pages.contact.courriel.courriel_string') + ': ' + t('pages.contact.champ_requis'));
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError(t('pages.contact.courriel.courriel_string') + ': ' + t('pages.contact.courriel.format_invalide'));
      isValid = false;
    } else {
      setEmailError('');
    }

    // Validatation des autres champs
    if (!prenom) { setPrenomError(t('pages.contact.prenom') + ': ' + t('pages.contact.champ_requis')); isValid = false; } else { setPrenomError(''); }
    if (!nom) { setNomError(t('pages.contact.nom_famille') + ': ' + t('pages.contact.champ_requis')); isValid = false; } else { setNomError(''); }
    if (!description) { setdescriptionError(t('pages.contact.description') + ': ' + t('pages.contact.champ_requis')); isValid = false; } else { setdescriptionError(''); }

    return isValid;
  }


  async function handleSubmit() {
    setLoading(true);

    if (!validateForm()) {
      setLoading(false);
      return;
    }

    console.log(`Envoi des infos vers ${sendmail_public_host}`)

    const formData = new FormData();
    formData.append('email', email);
    formData.append('telephone', telephone);
    formData.append('prenom', prenom);
    formData.append('nom', nom);
    formData.append('emplacement', emplacement);
    formData.append('date_debut', dateDebut);
    formData.append('description', description);
    formData.append('is_permis_ville', isDocVille.toString());
    formData.append('is_document_architecte', isDocArchitecte.toString());
    formData.append('pdf_file', fileName ? fileName : new File([''], 'fichier_vide.pdf'));

    await fetch(`https://${sendmail_public_host}/api/send_email`, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      body: formData,
    }).then((response) => {
      setLoading(false);

      if (response.status === 200) {
        toast.success(t('pages.contact.courriel.succes'), {
          duration: 4000,
        });
        setEmail('');
        setTelephone('');
        setPrenom('');
        setNom('');
        setEmplacement('');
        setDateDebut('');
        setDescription('');
        setIsDocVille(false);
        setIsDocArchitecte(false);
        setFileName(null);
      } else {
        toast.error(t('pages.contact.courriel.erreur'));
      }
    });
  }

  // https://uploadcare.com/blog/how-to-upload-file-in-react/
  const [fileName, setFileName] = useState<File | null>(null);
  const handleFile = (file: any) => {
    setFileName(file);
  };

  return (
    <div>
      <TitreCouleur texte={t('pages.contact.titre')} taille={3.2} />

      <div className={styles.flex_container}>
        <div className={styles.flex_infos}>


          <span  className={styles.custom_span}>{t('pages.contact.horaire_ouverture')}</span>
          {t('components.footer.horaire_semaine')} <br />


          <span  className={styles.custom_span}>{t('pages.contact.numero_telephone')}</span>
          <a href={`tel:${t('components.footer.numero_telephone')}`} className='global_regular_link_light_bg num_de_telef'>
            {t('components.footer.numero_telephone')}
          </a>


          <span  className={styles.custom_span}>{t('pages.contact.courriel.adresse')}</span>
          <a href={`mailto:${t('components.footer.courriel')}`} className='global_regular_link_light_bg'>
            {t('components.footer.courriel')}
          </a>


          <span  className={styles.custom_span}>{t('pages.contact.adresse')}</span>
          <a href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                      t('components.footer.adresse.rue') + ' ' +
                      t('components.footer.adresse.region') + ' ' +
                      t('components.footer.adresse.code_postal'))}&zoom=15 '))}`} className='global_regular_link_light_bg'>

                    {t('components.footer.adresse.rue')}
                    <br />
                    {t('components.footer.adresse.region')}
                    <br />
                    {t('components.footer.adresse.code_postal')}
          </a>
          <div>
            <br></br>
            <iframe title='deadbeef'
              height="350"
              loading="lazy"
              frameBorder="0"
              src={`https://www.google.com/maps/embed/v1/place?key=${var_glob._google_maps_api}&q=${encodeURIComponent(
                      t('components.footer.adresse.rue') +
                        ' ' +
                      t('components.footer.adresse.region') +
                        ' ' +
                      t('components.footer.adresse.code_postal'))}&zoom=15`}>
            </iframe>
          </div>
        </div>


        <div className={styles.flex_formulaire}>
          <div className={'global_form'} id="form_projet">


            {(emailError && <div className={'global_form_label global_form_label_erreur'} >{emailError}</div>) ||
              <label htmlFor="email" className={'global_form_label'}>{t('pages.contact.courriel.courriel_string')}
                <span className={'global_form_label global_form_label_erreur'}>*</span></label>}
            <input
              type='email'
              id='email'
              name="email"
              className={'global_text_field'}
              value={email}
              onChange={(e) => setEmail(e.target.value)} />

            <label htmlFor="telephone" className={'global_form_label'}>{t('pages.contact.numero_telephone')}</label>
            <input
              type='tel'
              id='telephone'
              name="telephone"
              className={'global_text_field'}
              value={telephone}
              onChange={(e) => setTelephone(e.target.value)} />

            {(prenomError && <div className={'global_form_label global_form_label_erreur'} >{prenomError}</div>) ||
              <label htmlFor="prenom" className={'global_form_label'}>{t('pages.contact.prenom')}
                <span className={'global_form_label global_form_label_erreur'}>*</span></label>}
            <input
              type='text'
              id='prenom'
              name="prenom"
              className={'global_text_field'}
              value={prenom}
              onChange={(e) => setPrenom(e.target.value)} />

            {(nomError && <div className={'global_form_label global_form_label_erreur'} >{nomError}</div>) ||
              <label htmlFor="nom" className={'global_form_label'}>{t('pages.contact.nom_famille')}
                <span className={'global_form_label global_form_label_erreur'}>*</span></label>}
            <input
              type='text'
              id='nom'
              name="nom"
              className={'global_text_field'}
              value={nom}
              onChange={(e) => setNom(e.target.value)} />

            <label htmlFor="emplacement" className={'global_form_label'}>{t('pages.contact.emplacement')}</label>
            <input
              type='text'
              id='emplacement'
              name="emplacement"
              className={'global_text_field'}
              value={emplacement}
              onChange={(e) => setEmplacement(e.target.value)} />

            <label htmlFor="date_debut_projet" className={'global_form_label'}>{t('pages.contact.date_debut_projet')}</label>
            <input
              type="date"
              id="date_debut_projet"
              name="date_debut_projet"
              className={'global_text_field'}
              value={dateDebut}
              onChange={(e) => setDateDebut(e.target.value)} />


            {(descriptionError && <div className={'global_form_label global_form_label_erreur'} >{descriptionError}</div>) ||
              <label htmlFor="description_projet" className={'global_form_label'}>{t('pages.contact.description')}
                <span className={'global_form_label global_form_label_erreur'}>*</span></label>}
            <textarea
              id='description_projet'
              name="description_projet"
              className={'global_text_field global_text_area'}
              value={description}
              onChange={(e) => setDescription(e.target.value)} />

            <div className={styles.single_row}>
              <input
                type='checkbox'
                id='documents_architecte'
                name="documents_architecte"
                checked={isDocArchitecte}
                onChange={() => setIsDocArchitecte(prevState => !prevState)} />
              <label htmlFor="documents_architecte" className={'global_form_label'}>{t('pages.contact.documents')}</label>
            </div>

            <div className={styles.single_row}>
              <input
                type='checkbox'
                id='documents_ville'
                name="documents_ville"
                checked={isDocVille}
                onChange={() => setIsDocVille(prevState => !prevState)} />
              <label htmlFor="documents_ville" className={'global_form_label'}>{t('pages.contact.permis')}</label>

            </div>

            <div className={styles.single_row}>
              <br></br>
              <label htmlFor="pdf_file" className={'contact_form_label'}>{t('pages.contact.le_fichier_zip')}</label>
              <br></br>
              <br></br>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <FileUploader handleFile={handleFile} />
                {fileName ? <div style={{ marginLeft: '10px' }}>{t('pages.contact.choix_de_fichier_a_televerser')} <b>{fileName?.name}</b></div> : null}
              </div>
              <br></br>
            </div>

            <button className={'global_button'} onClick={() => handleSubmit()}>
              {loading ? (
                <RotatingLines
                  strokeColor="black"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="18"
                  visible={true}
                />
              ) : (
                'Envoyer'
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
