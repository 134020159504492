import { useEffect, useState } from 'react';
import { Link, useNavigate,} from "react-router-dom";
import { db, auth } from '../../database';
import { useTranslation } from 'react-i18next';
import TitreCouleur from '../../components/TitreCouleur';
import { useAuthState } from 'react-firebase-hooks/auth';
import EcranChargement from '../../components/EcranChargement';
import { collection } from 'firebase/firestore';
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';
import styles from '../../styles/projets_grid_item.module.css'

import AdminBoutonSupprimer from '../../components/admin/BoutonSupprimer';

function AdminNouvelles() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [user, auth_loading] = useAuthState(auth);

    const [firestore_values, firestore_loading] = useCollectionDataOnce(
        collection(db, 'utilisateurs')
    );

    const [nouvelles, employes_loading, employes_error] = useCollectionDataOnce(
        collection(db, 'nouvelles')
    );

    const [loginStatus, setLoginStatus] = useState(false);

    // verifie si l'utilisateur est autorise dans la DB
    useEffect(() => {
        firestore_values?.forEach(element => {
            if (user?.uid === element.auth_userid) {
                setLoginStatus(true)
            }
        });
    }, [firestore_values, user?.uid]);

    if (auth_loading || firestore_loading || employes_loading) {
        return <EcranChargement />
    }

    if (!auth_loading && !user) {
        navigate("/admin/login")
        return (
            <div>
                <h2>Vous devez être connecté pour voir ceci.</h2>
            </div>
        )
    }

    if (user && !firestore_loading && !loginStatus) {
        return (
            <div>
                <h2>Vous n'êtes pas autorisé à voir ceci.</h2>
            </div>
        )
    }

    if (employes_error) {
        return (
            <div style={{ textAlign: 'center' }}>
                <h1>{t('global.erreur.erreur_bd.titre')}</h1>
                ---
                <h3>{employes_error.name} - {employes_error.code}</h3>
                <p>{employes_error.message}</p>
            </div>
        )
    }

    return (
        <div>
            <TitreCouleur texte='Nouvelles' taille={3.2} />

            <div className="global_grid">
                {
                    nouvelles?.map(nouvelle => {
                        return (
                            <div className={styles.card}>
                                <div className={styles.texte_carte}>
                                    <h2 className={styles.titre}>{nouvelle.titre}</h2>
                                    <p className={styles.description}>{nouvelle.sous_titre}</p>

                                    <Link className='global_navigation_link' to={'/admin/nouvelles/' + nouvelle.id.trim()}>
                                        <button className="global_button">
                                            Modifier
                                        </button>
                                    </Link>

                                    <br />
                                    <br />

                                    <AdminBoutonSupprimer
                                        titre_item={nouvelle.titre}
                                        collection_db={'nouvelles'}
                                        document_db={nouvelle.id.trim()}
                                    />
                                </div>
                            </div>
                        )
                    })}

                <div className='global_card' style={{ alignItems: 'center' }}>
                    <div>
                        <h2>Ajouter une nouvelle</h2>

                        <Link className='global_navigation_link' to={'/admin/nouvelles/nouvel_article'}>
                            <button className="global_button">
                                + Ajouter
                            </button>
                        </Link>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdminNouvelles;
