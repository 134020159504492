import { addDoc, collection, doc, updateDoc } from 'firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';
import { useNavigate, useParams } from "react-router-dom";
import EcranChargement from '../../components/EcranChargement';
import TitreCouleur from '../../components/TitreCouleur';
import { auth, db } from '../../database';

import toast from 'react-hot-toast';

function AdminNouveauProjet() {
    let { id } = useParams();
    const navigate = useNavigate();
    const storage = getStorage();



    const [selectedFiles, setSelectedFiles] = useState<FileList>();

    const [user, auth_loading ] = useAuthState(auth);

    const [firestore_values, firestore_loading ] = useCollectionDataOnce(
        collection(db, 'utilisateurs')
    );


    const [loginStatus, setLoginStatus] = useState(false);

    // valeurs pour le formulaire
    const [titreData, setTitre] = useState('');
    const [adresseData, setAdresse] = useState('');
    const [categorieData, setCategorie] = useState('');
    const [dateFinData, setDateFin] = useState('');
    const [descriptionData, setDescription] = useState('');
    const [descriptionCourteData, setDescriptionCourte] = useState('');

    // verifie si l'utilisateur est autorise dans la DB
    useEffect(() => {
        firestore_values?.forEach(element => {
            if (user?.uid === element.auth_userid) {
                setLoginStatus(true)
            }
        });
    }, [firestore_values, user?.uid]);

    if (auth_loading || firestore_loading) {
        return <EcranChargement />
    }

    if (!auth_loading && !user) {
        navigate("/admin/login")
        return (
            <div>
                <h2>Vous devez être connecté pour voir ceci.</h2>
            </div>
        )
    }

    if (user && !firestore_loading && !loginStatus) {
        return (
            <div>
                <h2>Vous n'êtes pas autorisé à voir ceci.</h2>
            </div>
        )
    }

    async function formSubmit() {
        const dateAjout = new Date().toISOString();
        const dateMaj = new Date().toISOString();

        await addDoc(collection(db, "projets"), {
            titre: titreData,
            adresse: adresseData,
            description: descriptionData,
            description_courte: descriptionCourteData,
            date_fin_des_travaux: dateFinData,
            categorie: categorieData,
            images: [''],
            date_ajout_projet: dateAjout,
            date_maj_projet: dateMaj
        }).then(function (docRef) {
            id = docRef.id;

            updateDoc(doc(db, "projets", id + ''), {
                id: id
            })
        }).catch(() => {
            toast.error('Une erreur est survenue. Veuillez réessayer plus tard.')
        })

        let urls = await uploadFichiers()
        await ajouterImagesAuProjet(urls)
        navigate('/admin/projets')
    }

    async function uploadFichiers(): Promise<string[]> {
        let urls: string[] = [];

        if (selectedFiles) {
          const uploadPromises: Promise<string>[] = [];

          for (let i = 0; i < selectedFiles.length; i++) {
            const titreImage = selectedFiles[i].size + selectedFiles[i].name;
            const imageRef = ref(storage, `/projets/${id}/${titreImage}`);

            const uploadPromise = uploadBytes(imageRef, selectedFiles[i])
              .then((snapshot) => getDownloadURL(snapshot.ref))
              .then((downloadURL) => {
                console.log(downloadURL);
                return downloadURL;
              });

            uploadPromises.push(uploadPromise);
          }

          try {
            urls = await Promise.all(uploadPromises);
          } catch (error) {
            toast.error('Une erreur est survenue. Veuillez réessayer plus tard: ' + error)
          }
        }

        console.log("urls avant", urls);
        return urls;
      }

    async function ajouterImagesAuProjet(urls: Array<string>) {
        console.log("AjouterImagesAuProjet", urls)

        updateDoc(doc(db, "projets", id + ''), {
            images: urls

        }).then(() => {
            toast.success('Informations enregistrées.')

        }).catch((error) => {
            toast.error('Une erreur est survenue. Veuillez réessayer plus tard: ' + error)
        })
    }

    return (
        <div>
            <TitreCouleur texte='Ajouter un projet' taille={3.2} />

            <div className='global_form center'>

                <label htmlFor="titre" className='global_form_label'>Titre</label>
                <input
                    className='global_text_field'
                    type="text"
                    value={titreData}
                    onChange={(e) => setTitre(e.target.value)}
                />

                <label htmlFor="adresse" className='global_form_label'>Adresse/emplacement</label>
                <input
                    className='global_text_field'
                    type="text"
                    value={adresseData}
                    onChange={(e) => setAdresse(e.target.value)}
                />

                <label htmlFor="descriptionCourte" className='global_form_label'>Description courte</label>
                <input
                    className='global_text_field'
                    type="text"
                    value={descriptionCourteData}
                    onChange={(e) => setDescriptionCourte(e.target.value)}
                />

                <label htmlFor="description" className='global_form_label'>Description longue</label>
                <textarea
                    className='global_text_field global_text_area'
                    value={descriptionData}
                    onChange={(e) => setDescription(e.target.value)} />

                <label htmlFor="dateFin" className='global_form_label'>Date de fin du projet</label>
                <input
                    className='global_text_field'
                    type="date"
                    value={dateFinData}
                    onChange={(e) => setDateFin(e.target.value)}
                />

                <label htmlFor="categorie" className='global_form_label'>Catégorie</label>
                <select
                    className='global_text_field'
                    id="categorie"
                    name='categorie'
                    value={categorieData}
                    defaultValue={'Résidentiel'}
                    onChange={(e) => setCategorie(e.target.value)}>
                    <option value="Résidentiel">Résidentiel</option>
                    <option value="Commercial">Commercial</option>
                    <option value="Industriel">Industriel</option>
                </select>

                <input
                    type="file"
                    multiple
                    onChange={(e) => {
                        const file = e.target.files ? e.target.files : undefined;
                        setSelectedFiles(file);
                    }}
                />

                <button className='global_button' onClick={async () => await formSubmit()}>
                    Ajouter!
                </button>
            </div>
        </div>
    );
}

export default AdminNouveauProjet;
